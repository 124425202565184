import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  ContactStateProps,
  ContactDispatchProps,
  ContactProps,
  ContactState,
} from "./Contact.types";
import "./Contact.style.scss";
import LeftHeader from "../../shared/LeftHeader/LeftHeader";
import ContentBox from "../../shared/ContentBox/ContentBox";
import {
  fetchFromApi,
  getStorage,
  isValidate,
  JoiEmailAddress,
  JoiMessage,
  JoiPhoneNumber,
  JoiPolishSupportType,
  JoiSubject,
  JoiSupportType,
  setStorage,
} from "../../../core/utils";
import ReactHtmlParser from "react-html-parser";
import qs from "qs";
import Joi from "joi";
import { withRouter } from "react-router";

const mapStateToProps = (state: AppState): ContactStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ContactDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Contact extends React.Component<ContactProps, ContactState> {
  public state: ContactState = {
    supportType: undefined,
    contactSingleton: undefined,
    emailAddress: "",
    applicationEmailAddress: "",
    phoneNumber: "",
    subject: "",
    message: "",
    lastMessageAt: undefined,
    lastApplicationAt: undefined,
    isPrivacyPolicyAccepted: false,
  };
  public constructor(props: ContactProps) {
    super(props);
  }

  public componentDidMount() {
    this.handleLocationSearchChange();
    this.load();
    this.fetchContactSingleton();
  }

  public componentDidUpdate(oldProps: ContactProps, oldState: ContactState) {
    const { props } = this;
    if (props.location.search !== oldProps.location.search)
      this.handleLocationSearchChange();
  }

  public handleLocationSearchChange = () => {
    const { location } = this.props;
    const { search } = location;
    const searchObj = qs.parse(search.slice(1));
    console.log(searchObj);
    if (searchObj["zostan"]) {
      const supportTypes = {
        patronem: "sponsor",
        partnerem: "partner",
      };
      const polishSupportType = searchObj["zostan"];
      const isPolishSupportTypeValidate = isValidate(
        JoiPolishSupportType,
        polishSupportType
      );
      console.log(
        polishSupportType,
        supportTypes[polishSupportType as "patronem" | "partnerem"] as
          | "partner"
          | "sponsor"
      );
      isPolishSupportTypeValidate &&
        this.setState({
          supportType: supportTypes[
            polishSupportType as "patronem" | "partnerem"
          ] as "partner" | "sponsor",
        });
    }
  };

  public load = () => {
    const ls = getStorage();
    this.setState({
      lastMessageAt: ls?.lastMessageAt,
      lastApplicationAt: ls?.lastApplicationAt,
      emailAddress: ls?.emailAddress || "",
      applicationEmailAddress: ls?.emailAddress || "",
    });
  };

  public fetchContactSingleton = async () => {
    const contactSingleton = await fetchFromApi<ContactSingleton, "singletons">(
      "singletons",
      "contact"
    );
    this.setState({ contactSingleton });
  };

  public isFormValidate = (): boolean => {
    const { emailAddress, subject, message, isPrivacyPolicyAccepted } =
      this.state;

    const isEmailAddressValidate = isValidate(JoiEmailAddress, emailAddress);
    const isSubjectValidate = isValidate(JoiSubject, subject);
    const isMessageValidate = isValidate(JoiMessage, message);
    return (
      isEmailAddressValidate &&
      isSubjectValidate &&
      isMessageValidate &&
      isPrivacyPolicyAccepted
    );
  };

  public isApplicationValidate = (): boolean => {
    const {
      applicationEmailAddress,
      phoneNumber,
      supportType,
      isPrivacyPolicyAccepted,
    } = this.state;

    const isSupportTypeValidate = isValidate(JoiSupportType, supportType);
    const isEmailAddressValidate = isValidate(
      JoiEmailAddress,
      applicationEmailAddress
    );
    const isPhoneNumberValidate = isValidate(JoiPhoneNumber, phoneNumber);
    return (
      isEmailAddressValidate &&
      isPhoneNumberValidate &&
      isSupportTypeValidate &&
      isPrivacyPolicyAccepted
    );
  };

  public handleEmailAddressInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: emailAddress } = event.target;
    this.setState({ emailAddress });
  };

  public handleSubjectInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: subject } = event.target;
    this.setState({ subject });
  };

  public handleMessageTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value: message } = event.target;
    this.setState({ message });
  };

  public handleApplicationEmailAddressInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: applicationEmailAddress } = event.target;
    this.setState({ applicationEmailAddress });
  };

  public handlePhoneNumberInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: phoneNumber } = event.target;
    this.setState({ phoneNumber });
  };

  public handleSubmitButtonClick = async () => {
    const { emailAddress, subject, message } = this.state;
    const messageForm = await fetchFromApi<MessageForm, "forms">(
      "forms",
      "messages",
      {
        emailAddress,
        subject,
        message,
      }
    );
    const lastMessageAt: number = Date.now();
    this.setState({ lastMessageAt });
    setStorage({ lastMessageAt });
    this.resetForm();
  };

  public handleSubmitApplicationButtonClick = async () => {
    const { applicationEmailAddress, phoneNumber, supportType } = this.state;
    const messageForm = await fetchFromApi<ApplicationForm, "forms">(
      "forms",
      "applications",
      {
        applicationEmailAddress,
        phoneNumber,
        supportType: supportType as "partner" | "sponsor",
      }
    );
    const lastApplicationAt: number = Date.now();
    this.setState({ lastApplicationAt });
    setStorage({ lastApplicationAt });
  };

  public handleResetButtonClick = () => {
    this.resetForm();
  };

  public resetForm = () => {
    this.setState({ emailAddress: "", subject: "", message: "" });
  };

  public handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: supportType } = event.target;
    this.setState({ supportType: supportType as "partner" | "sponsor" });
  };

  public handleCheckboxChange = () => {
    const { isPrivacyPolicyAccepted } = this.state;
    this.setState({ isPrivacyPolicyAccepted: !isPrivacyPolicyAccepted });
  };

  public renderPrivacyPolicyLabel = () => {
    const { isPrivacyPolicyAccepted } = this.state;
    return (
      <div className="field privacy-policy">
        <label className="main"></label>
        <div className="box">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={isPrivacyPolicyAccepted}
              onChange={this.handleCheckboxChange}
            />
            <span className="privacy-policy-text">
              Akceptuję politykę prywatności.
            </span>
          </label>
        </div>
      </div>
    );
  };

  public render(): JSX.Element {
    const {} = this.props;
    const {
      contactSingleton,
      applicationEmailAddress,
      emailAddress,
      subject,
      phoneNumber,
      message,
      lastMessageAt,
      lastApplicationAt,
      supportType,
      isPrivacyPolicyAccepted,
    } = this.state;
    const messageSent: boolean = Boolean(
      lastMessageAt && lastMessageAt >= Date.now() - 15 * 1000 * 60
    );
    return (
      <div className={"contact"}>
        <ContentBox
          imagePath={contactSingleton?.backgroundImage?.path}
          hideBackButton={true}
          sections={[
            {
              wysiwygable: true,
              header: "Kontakt",
              item: (
                <div className="contact-item">
                  <div className="form">
                    <div className="left side">
                      <p className="wysiwyg">
                        {contactSingleton?.leftSideContent &&
                          ReactHtmlParser(contactSingleton.leftSideContent)}
                      </p>
                      <div className="inputs">
                        <div className="field">
                          <label className="main">Twój adres e-mail</label>
                          <div className="box">
                            <input
                              type="email"
                              onChange={this.handleEmailAddressInputChange}
                              value={emailAddress}
                              disabled={messageSent}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="main">Temat</label>
                          <div className="box">
                            <input
                              type="text"
                              onChange={this.handleSubjectInputChange}
                              value={subject}
                              disabled={messageSent}
                            />
                          </div>
                        </div>
                        <div className="field area">
                          <label className="main">Treść</label>
                          <div className="box">
                            <textarea
                              onChange={this.handleMessageTextareaChange}
                              value={message}
                              disabled={messageSent}
                            ></textarea>
                          </div>
                        </div>
                        {this.renderPrivacyPolicyLabel()}
                      </div>

                      <div className="buttons">
                        <label className="main"></label>
                        <div className="box">
                          {!messageSent && (
                            <>
                              <button
                                className="main"
                                onClick={this.handleSubmitButtonClick}
                                disabled={!this.isFormValidate() || messageSent}
                              >
                                Wyślij
                              </button>
                              <button
                                className="reset"
                                onClick={this.handleResetButtonClick}
                              >
                                Wyczyść
                              </button>
                            </>
                          )}
                          {messageSent && (
                            <div className="message-sent">
                              <span>Wiadomość została wysłana!</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="right side">
                      <p className="wysiwyg">
                        {contactSingleton?.rightSideContent &&
                          ReactHtmlParser(contactSingleton.rightSideContent)}
                      </p>
                      <div className="inputs">
                        <div className="field">
                          <label className="main">Twój adres e-mail</label>
                          <div className="box">
                            <input
                              type="email"
                              onChange={
                                this.handleApplicationEmailAddressInputChange
                              }
                              value={applicationEmailAddress}
                              disabled={lastApplicationAt !== undefined}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="main">Numer telefonu</label>
                          <div className="box">
                            <input
                              type="text"
                              onChange={this.handlePhoneNumberInputChange}
                              value={phoneNumber}
                              disabled={lastApplicationAt !== undefined}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="main">Chcę zostać</label>
                          <div className="box">
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="partner"
                                name="support-type"
                                checked={supportType === "partner"}
                                onChange={this.handleRadioButtonChange}
                                disabled={lastApplicationAt !== undefined}
                              />
                              <span>Partnerem</span>
                            </label>
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="sponsor"
                                name="support-type"
                                checked={supportType === "sponsor"}
                                onChange={this.handleRadioButtonChange}
                                disabled={lastApplicationAt !== undefined}
                              />
                              <span>Patronem</span>
                            </label>
                          </div>
                        </div>
                        <div className="field">
                          <label className="main"></label>
                          <div className="box">
                            <div className="apply-image"></div>
                          </div>
                        </div>
                        {this.renderPrivacyPolicyLabel()}
                      </div>
                      <div className="buttons">
                        <label className="main"></label>
                        <div className="box">
                          {lastApplicationAt === undefined && (
                            <button
                              className="main"
                              onClick={this.handleSubmitApplicationButtonClick}
                              disabled={!this.isApplicationValidate()}
                            >
                              Dołącz do nas
                            </button>
                          )}
                          {lastApplicationAt !== undefined && (
                            <div className="message-sent">
                              <span>Dziękujemy za złożenie aplikacji!</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(Contact);
