import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  ScrollableListStateProps,
  ScrollableListDispatchProps,
  ScrollableListProps,
  ScrollableListState,
} from "./ScrollableList.types";
import "./ScrollableList.style.scss";
import Arrows from "../Arrows/Arrows";

const mapStateToProps = (state: AppState): ScrollableListStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): ScrollableListDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class ScrollableList extends React.Component<
  ScrollableListProps,
  ScrollableListState
> {
  public resizeTimer: NodeJS.Timeout | undefined;
  public wrapperRef: React.RefObject<HTMLDivElement>;
  public firstChildRef: React.RefObject<HTMLDivElement>;
  public state: ScrollableListState = {
    lastDisplaydItemIdx: 0,
    windowWidth: undefined,
    windowHeight: undefined,
  };
  public constructor(props: ScrollableListProps) {
    super(props);
    this.wrapperRef = React.createRef();
    this.firstChildRef = React.createRef();
  }

  public componentDidMount() {
    const { maxDisplayedItems } = this.props;
    this.setState({ lastDisplaydItemIdx: maxDisplayedItems - 1 });
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  public componentDidUpdate(
    oldProps: ScrollableListProps,
    oldState: ScrollableListState
  ) {
    const { state } = this;
    if (state.windowWidth !== oldState.windowWidth) this.resetScroll();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  public handleResize = () => {
    const { resizeTimer } = this;
    resizeTimer && clearTimeout(resizeTimer);
    setTimeout(() => {
      this.setState({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      });
    }, 500);
  };

  public getScrollValue = (): number => {
    const { current: firstChildElement } = this.firstChildRef;
    return firstChildElement?.clientWidth || 0;
  };

  public handleScrollLeft = () => {
    this.wrapperRef?.current?.scrollBy({
      left: -this.getScrollValue(),
      behavior: "smooth",
    });
  };

  public handleScrollRight = () => {
    this.wrapperRef?.current?.scrollBy({
      left: this.getScrollValue(),
      behavior: "smooth",
    });
  };

  public resetScroll = () => {
    this.wrapperRef?.current?.scroll({
      left: 0,
      behavior: "smooth",
    });
  };

  public getDisplayedItems = (maxDisplayedItems: number) => {
    const { windowWidth } = this.state;
    if (!windowWidth) return maxDisplayedItems;

    const maxWidth: number = 1250;
    const widthRatio: number = windowWidth / maxWidth;
    if (widthRatio > 1) return maxDisplayedItems;

    return Math.round(widthRatio * maxDisplayedItems);
  };

  public render(): JSX.Element {
    const { items, maxDisplayedItems, className, arrowsStyle } = this.props;
    const {} = this.state;

    const displayedItems = this.getDisplayedItems(maxDisplayedItems);

    return (
      <div className={`scrollablelist ${className}`}>
        <Arrows
          onScrollLeft={this.handleScrollLeft}
          onScrollRight={this.handleScrollRight}
          style={arrowsStyle}
        />
        <div className="scrollable-box">
          <div className="item-wrapper" ref={this.wrapperRef}>
            <div
              className="inner"
              style={{ width: `${(items.length * 100) / displayedItems}%` }}
            >
              {items.map((item, idx) => (
                <div
                  className="item"
                  ref={idx === 0 ? this.firstChildRef : undefined}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bottom-box"></div>
      </div>
    );
  }
}

export default ScrollableList;
