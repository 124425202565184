import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  SocialMediaStateProps,
  SocialMediaDispatchProps,
  SocialMediaProps,
  SocialMediaState,
} from "./SocialMedia.types";
import "./SocialMedia.style.scss";
import { IconType } from "react-icons";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
  FaSearch,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const mapStateToProps = (state: AppState): SocialMediaStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): SocialMediaDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class SocialMedia extends React.Component<SocialMediaProps, SocialMediaState> {
  public state: SocialMediaState = {};
  public constructor(props: SocialMediaProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(
    oldProps: SocialMediaProps,
    oldState: SocialMediaState
  ) {}

  public getSocialMediaComponent = (kind: SocialMediaKind) => {
    const Components: {
      [key in SocialMediaKind]: IconType;
    } = {
      facebook: FaFacebookF,
      twitter: FaTwitter,
      instagram: FaInstagram,
      youtube: FaYoutube,
      linkedin: FaLinkedinIn,
      pinterest: FaPinterestP,
    };

    const Component = Components[kind];
    return <Component size={12} />;
  };

  public render(): JSX.Element {
    const { socialMedias } = this.props;
    const {} = this.state;

    return (
      <div className="social-medias">
        {socialMedias.map((socialMedia, i) => (
          <a
            href={socialMedia.url}
            key={i}
            target="_blank"
            rel="noreferrer"
            className="square"
          >
            {this.getSocialMediaComponent(socialMedia.kind)}
          </a>
        ))}
      </div>
    );
  }
}

export default SocialMedia;
