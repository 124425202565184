import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  MenuStateProps,
  MenuDispatchProps,
  MenuProps,
  MenuState,
} from "./Menu.types";
import "./Menu.style.scss";

import { Link, NavLink } from "react-router-dom";
import SocialMedia from "../SocialMedia/SocialMedia";
import Search from "../Search/Search";
import _menuOptions from "./menu-options.json";

const mapStateToProps = (state: AppState): MenuStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): MenuDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type MenuOption = {
  name: string;
  to: string;
  exact: boolean;
  hideInMenu?: boolean;
};

class Menu extends React.Component<MenuProps, MenuState> {
  public state: MenuState = {
    more: false,
  };
  public constructor(props: MenuProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(oldProps: MenuProps, oldState: MenuState) {}

  public handleMoreOptionClick = () => {
    const { more } = this.state;
    this.setState({ more: !more });
  };

  public render(): JSX.Element {
    const { socialMedias, isFixed } = this.props;
    const { more } = this.state;

    const menuOptions = (_menuOptions as MenuOption[]).filter(
      (option) => !option.hideInMenu
    );

    return (
      <div className={`menu ${isFixed ? "fixed" : ""}`}>
        <div className="menu-inner">
          <div className="options">
            {menuOptions.map((option: MenuOption) => (
              <NavLink
                to={option.to}
                exact={option.exact}
                className="menu-option basic"
                activeClassName="selected"
              >
                {option.name}
              </NavLink>
            ))}
            <div
              className={`menu-option more ${more ? "selected" : ""}`}
              onClick={this.handleMoreOptionClick}
            >
              <div className="menu-select-label">
                <span>Więcej</span>
              </div>
              {more && (
                <div className={`menu-select`}>
                  {menuOptions.map((option: MenuOption) => (
                    <NavLink
                      to={option.to}
                      exact={option.exact}
                      className="menu-option menu-select-option"
                      activeClassName="selected"
                    >
                      {option.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="other">
            <div className="mini-logo"></div>
            <Search />
            <SocialMedia socialMedias={socialMedias} />
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
