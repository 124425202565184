import Joi from "joi";

export const getApiUrl = () =>
  process.env.NODE_ENV === "production"
    ? `https://monitorpowszechny.pl`
    : `https://monitorpowszechny.pl`;

type FilterKeyOptions<T, K extends keyof T> = {
  $not?: T[K];
  $lt?: T[K];
  $lte?: T[K];
  $gt?: T[K];
  $gte?: T[K];
  $regex?: T[K];
  $has?: T[K] extends string[] | null ? string : never;
};

type FilterLogicalOptions<T> = {
  $and?: Array<{ [K in keyof T]?: FilterKeyOptions<T, K> | T[K] }>;
  $or?: Array<{ [K in keyof T]?: FilterKeyOptions<T, K> | T[K] }>;
};

export const getImageUrl = (image: Image | undefined) => {
  if (!image) return ``;
  return `${getApiUrl()}${image.path}`;
};

export const fetchFromApi = async <
  T,
  U extends "singletons" | "collections" | "forms" = "singletons"
>(
  kind: U,
  endPoint: U extends "singletons"
    ? "contact" | "footer" | "aboutUs" | "partners"
    : U extends "collections"
    ? "slider" | "blogs" | "socialMedia" | "team" | "shareholders" | "sections"
    : "messages" | "newsletter" | "applications",
  form?: { [K in keyof T]: T[K] } | undefined,
  options?: {
    filter?:
      | {
          [K in keyof Entry<T>]?: Entry<T>[K] extends CollectionLink<string> | null
            ? FilterKeyOptions<Entry<T>, K> | string | null
            : FilterKeyOptions<Entry<T>, K> | Entry<T>[K] | null;
        }
      | FilterLogicalOptions<T>;
    fields?: { [K in keyof Entry<T>]?: boolean };
    limit?: number;
    skip?: number;
    sort?: { [K in keyof Entry<T>]?: 1 | -1 };
  }
): Promise<U extends "singletons" ? T : Entry<T>[]> => {
  const action = kind === "forms" ? "submit" : "get";
  const method = kind === "forms" || options !== undefined ? "POST" : "GET";
  const token =
    process.env.NODE_ENV === "production"
      ? `6d075e81630710d2ba764ea79f65e2`
      : `6d075e81630710d2ba764ea79f65e2`;
  const response = await fetch(
    `${getApiUrl()}/cockpit-master/api/${kind}/${action}/${endPoint}?token=${token}`,
    {
      method,
      headers: { "Content-Type": "application/json" },
      body:
        method === "POST" ? JSON.stringify({ form, ...options }) : undefined,
    }
  );
  const data = await response.json();
  return kind === "collections" ? data.entries : data;
};

export const isValidate = (schema: Joi.Schema, value: any): boolean => {
  return schema.validate(value).error === undefined;
};

export const localstorageVersion = `v1`;

export type LocalStorageObj = {
  lastApplicationAt?: number | undefined;
  lastMessageAt?: number | undefined;
  lastNewsletterAdded?: number | undefined;
  emailAddress?: string | undefined;
  isCookiesAccepted?: boolean | undefined;
};

export const getStorage = (): LocalStorageObj | undefined => {
  const ls = localStorage.getItem(localstorageVersion);
  return ls && JSON.parse(ls);
};

export const setStorage = (items: Partial<LocalStorageObj>): void => {
  const ls = getStorage();
  localStorage.setItem(
    localstorageVersion,
    JSON.stringify({ ...ls, ...items })
  );
};

export const days = [
  "niedziela",
  "poniedziałek",
  "wtorek",
  "środa",
  "czwartek",
  "piątek",
  "sobota",
];

export const shiftDays = (days: string[]) => {
  const newDays = days.map((day) => day);
  var b = newDays.shift() as string;
  newDays[newDays.length] = b;
  return newDays;
};

export const monthNames: string[] = [
  "styczeń",
  "luty",
  "marzec",
  "kwiecień",
  "maj",
  "czerwiec",
  "lipiec",
  "sierpień",
  "wrzesień",
  "październik",
  "listopad",
  "grudzień",
];

export const changedMonthNames: string[] = [
  "stycznia",
  "lutego",
  "marca",
  "kwietnia",
  "maja",
  "czerwca",
  "lipca",
  "sierpnia",
  "września",
  "października",
  "listopada",
  "grudnia",
];

export const getDateString = (
  date: Date,
  year?: number,
  month?: number,
  day?: number
) => {
  const dateYear: number = year || date.getFullYear();
  const dateMonth: number = (month && month + 1) || date.getMonth() + 1;
  const dateDay: number = day || date.getDate();

  const monthStr = dateMonth < 10 ? `0${dateMonth}` : `${dateMonth}`;
  const dayStr = dateDay < 10 ? `0${dateDay}` : `${dateDay}`;

  return `${dateYear}-${monthStr}-${dayStr}`;
};

export const getFormattedDate = (dateInput: string | number): string => {
  const date = new Date(dateInput);
  return `${date.getDate()} ${
    changedMonthNames[date.getMonth()]
  } ${date.getFullYear()}`;
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function groupBy<T>(xs: T[], key: keyof T): Array<T[]> {
  return Object.values(
    xs.reduce(function (rv, x) {
      ((rv as any)[x[key]] = (rv as any)[x[key]] || []).push(x);
      return rv;
    }, {})
  );
}

export const getFirstDayOfMonth = (
  year: number | undefined,
  month: number | undefined
): number => {
  if (year === undefined) return 1;
  if (month === undefined) return 1;
  return new Date(year, month, 1).getDay();
};

export const getDayCountInMonth = (
  year: number | undefined,
  month: number | undefined
): number => {
  if (!year) return 31;
  if (!month) return 31;
  return new Date(year, month + 1, 0).getDate();
};

export const encodeBlogUrl = (blogTitle: string) => {
  return blogTitle.replaceAll("+", "%2B").replaceAll(" ", "+");
};

export const decodeBlogUrl = (blogTitle: string): string => {
  return blogTitle.replaceAll("+", " ").replaceAll("%2B", "+");
};

export const schuffle = <T = any>(array: T[]): T[] => {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

export function throttle<T>(callbackFn: (...args: T[]) => any, limit: number) {
  let wait = false;
  return function (...args: T[]) {
    if (!wait) {
      callbackFn.call(Object.values(args));
      wait = true;
      setTimeout(function () {
        wait = false;
      }, limit);
    }
  };
}

/** forms */

export const JoiEmailAddress = Joi.string()
  .email({ tlds: { allow: false } })
  .min(5)
  .max(40)
  .required();
export const JoiSubject = Joi.string().min(4).max(30).required();
export const JoiMessage = Joi.string().min(10).max(250).required();
export const JoiPhoneNumber = Joi.string()
  .regex(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)
  .min(9)
  .max(17)
  .required();

export const JoiSupportType = Joi.string()
  .valid("sponsor", "partner")
  .required();

export const JoiPolishSupportType = Joi.string()
  .valid("patronem", "partnerem")
  .required();
