import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  TileStateProps,
  TileDispatchProps,
  TileProps,
  TileState,
} from "./Tile.types";
import "./Tile.style.scss";
import Badge from "../Badge/Badge";

const mapStateToProps = (state: AppState): TileStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): TileDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Tile extends React.Component<TileProps, TileState> {
  public state: TileState = {
    isMore: false,
  };
  public constructor(props: TileProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(oldProps: TileProps, oldState: TileState) {}

  public handleMoreClick = () => {
    const { isMore } = this.state;
    this.setState({ isMore: !isMore });
  };

  public render(): JSX.Element {
    const { badgeText, title, description, imagePath, style, additionalInfo } =
      this.props;
    const { isMore } = this.state;

    return (
      <div className={`tile ${isMore ? "extend" : ""}`} style={style}>
        <div className="inner">
          <div className="photo-wrapper">
            <div
              className="photo"
              style={{
                backgroundImage: `url(${imagePath})`,
              }}
            ></div>
          </div>
          <div className="info-box">
            <div className="inner">
              <div className="shadow-box-wrapper">
                <div className="shadow-box"></div>
              </div>
              <div className="white-box">
                <div className="content">
                  {badgeText && <Badge>{badgeText}</Badge>}
                  <div className="title">
                    <span>{title}</span>
                  </div>
                  <div className="line">
                    <div className="line-inner"></div>
                  </div>
                  <div className="description">
                    <span>{description}</span>
                  </div>
                  <div className="more" onClick={this.handleMoreClick}>
                    {!isMore && <span>więcej</span>}
                    {isMore && <span>mniej</span>}
                  </div>
                </div>
                <div className="additional-info">{additionalInfo}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="tile-bottom"></div>
      </div>
    );
  }
}

export default Tile;
