import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  ArrowsStateProps,
  ArrowsDispatchProps,
  ArrowsProps,
  ArrowsState,
} from "./Arrows.types";
import "./Arrows.style.scss";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const mapStateToProps = (state: AppState): ArrowsStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): ArrowsDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Arrows extends React.Component<ArrowsProps, ArrowsState> {
  public state: ArrowsState = {
    currentChildIdx: 0,
  };
  public constructor(props: ArrowsProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(oldProps: ArrowsProps, oldState: ArrowsState) {}

  public handleArrowLeftClick = () => {
    const { onScrollLeft } = this.props;
    onScrollLeft && onScrollLeft();
  };

  public handleArrowRightClick = () => {
    const { onScrollRight } = this.props;
    onScrollRight && onScrollRight();
  };

  public render(): JSX.Element {
    const { style } = this.props;
    const {} = this.state;

    return (
      <div className="Arrows" style={style}>
        <div className="arrow left" onClick={this.handleArrowLeftClick}>
          <FaAngleLeft />
        </div>
        <div className="arrow right" onClick={this.handleArrowRightClick}>
          <FaAngleRight />
        </div>
      </div>
    );
  }
}

export default Arrows;
