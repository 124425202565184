import React, { KeyboardEventHandler } from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  TopStateProps,
  TopDispatchProps,
  TopProps,
  TopState,
} from "./Top.types";
import "./Top.style.scss";
import OutsideClickHandler from "react-outside-click-handler";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
  FaSearch,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { IconType } from "react-icons";
import Badge from "../../shared/Badge/Badge";
import {
  changedMonthNames,
  days,
  fetchFromApi,
  getDateString,
  sleep,
} from "../../../core/utils";
import { ImSpinner2, ImSpinner8 } from "react-icons/im";
import AnimationableLink from "../../shared/AnimationableLink/AnimationableLink";
import { Link } from "react-router-dom";

const mapStateToProps = (state: AppState): TopStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): TopDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Top extends React.Component<TopProps, TopState> {
  public state: TopState = {
    isPending: false,
    callendarEventsResult: [],
    searchInputValue: "",
  };
  public constructor(props: TopProps) {
    super(props);
  }

  public getSocialMediaComponent = (kind: SocialMediaKind) => {
    const Components: {
      [key in SocialMediaKind]: IconType;
    } = {
      facebook: FaFacebookF,
      twitter: FaTwitter,
      instagram: FaInstagram,
      youtube: FaYoutube,
      linkedin: FaLinkedinIn,
      pinterest: FaPinterestP,
    };

    const Component = Components[kind];
    return <Component size={10} />;
  };

  public componentDidMount() {}

  public componentDidUpdate(oldProps: TopProps, oldState: TopState) {}

  public render(): JSX.Element {
    const { topRef } = this.props;
    const currentDate: Date = new Date();
    return (
      <div className={"top"} ref={topRef}>
        <div className="current-date">
          <span>
            {days[currentDate.getDay()]}, {currentDate.getDate()}{" "}
            {changedMonthNames[currentDate.getMonth()]}{" "}
            {currentDate.getFullYear()}
          </span>
        </div>
        <Link to={"/"}>
          <div className="logo"></div>
        </Link>
      </div>
    );
  }
}

export default Top;
