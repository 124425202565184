import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import Top from "./components/entities/Top/Top";
import PageContent from "./components/entities/PageContent/PageContent";
import Footer from "./components/entities/Footer/Footer";
import Menu from "./components/entities/Menu/Menu";
import Partners from "./components/entities/Partners/Partners";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { fetchFromApi, throttle } from "./core/utils";
import ScrollToTop from "./components/shared/ScrollToTop/ScrollToTop";
import "./assets/styles/responsive.scss";
import CookiesWindow from "./components/entities/CookiesWindow/CookiesWindow";

const history = createBrowserHistory();

type AppState = {
  isMenuFixed: boolean;
  socialMedias: Entry<SocialMedia>[];
  shareholders: Shareholder[];
  footerSingleton: FooterSingleton | undefined;
  sections: Section[];
};

class App extends React.Component<any, AppState> {
  public scrollTimer: NodeJS.Timeout | undefined;
  public topRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: AppState = {
    isMenuFixed: false,
    socialMedias: [],
    shareholders: [],
    footerSingleton: undefined,
    sections: [],
  };

  componentDidMount() {
    this.listenScroll();
    this.fetchSocialMedia();
    this.fetchShareholders();
    this.fetchFooterSingleton();
    this.fetchSections();
  }

  public listenScroll = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  public handleScroll = (event: Event) => {
    const { topRef } = this;
    const { current: topElement } = topRef;
    const { isMenuFixed } = this.state;
    if (window.scrollY < (topElement?.clientHeight || 150))
      return isMenuFixed && this.setState({ isMenuFixed: false });
    !isMenuFixed && this.setState({ isMenuFixed: true });
  };

  public fetchSections = async () => {
    const sections = await fetchFromApi<Section, "collections">(
      "collections",
      "sections"
    );
    this.setState({ sections });
  };

  public fetchSocialMedia = async () => {
    const socialMedias = await fetchFromApi<SocialMedia, "collections">(
      "collections",
      "socialMedia"
    );
    this.setState({ socialMedias });
  };

  public fetchShareholders = async () => {
    const shareholders = await fetchFromApi<Shareholder, "collections">(
      "collections",
      "shareholders"
    );
    this.setState({ shareholders });
  };

  public fetchFooterSingleton = async () => {
    const footerSingleton = await fetchFromApi<FooterSingleton, "singletons">(
      "singletons",
      "footer"
    );
    this.setState({ footerSingleton });
  };

  render() {
    const { topRef } = this;
    const {
      isMenuFixed,
      socialMedias,
      footerSingleton,
      shareholders,
      sections,
    } = this.state;

    return (
      <Router history={history}>
        <ScrollToTop />
        <div className="App">
          <CookiesWindow />
          <div className="inner">
            <Top topRef={topRef} />
            <Menu isFixed={isMenuFixed} socialMedias={socialMedias} />
            <PageContent />
            <Partners shareholders={shareholders} />
          </div>

          <Footer
            sections={sections}
            footerSingleton={footerSingleton}
            socialMedias={socialMedias}
          />
        </div>
      </Router>
    );
  }
}

export default App;
