import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  BlogPreviewStateProps,
  BlogPreviewDispatchProps,
  BlogPreviewProps,
  BlogPreviewState,
} from "./BlogPreview.types";
import "./BlogPreview.style.scss";
import {
  encodeBlogUrl,
  getFormattedDate,
  getImageUrl,
} from "../../../core/utils";
import { motion } from "framer-motion";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import striptags from "striptags";

const mapStateToProps = (state: AppState): BlogPreviewStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): BlogPreviewDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class BlogPreview extends React.Component<BlogPreviewProps, BlogPreviewState> {
  public state: BlogPreviewState = {};
  public constructor(props: BlogPreviewProps) {
    super(props);
  }

  public componentDidMount() {}

  public componentDidUpdate(
    oldProps: BlogPreviewProps,
    oldState: BlogPreviewState
  ) {}

  public render(): JSX.Element {
    const { hideImage, className, flexDirection, blog, showTags } = this.props;
    const {} = this.state;

    return (
      <Link
        to={
          blog ? `/blog/${blog._created}/${encodeBlogUrl(blog.title)}` : `/blog`
        }
        className={`blogpreview ${className}`}
        style={{ flexDirection }}
      >
        {!hideImage && (
          <div className="blog-preview-image-wrapper">
            <div
              className="image"
              style={{
                backgroundImage: `url(${getImageUrl(blog?.image)})`,
              }}
            ></div>
          </div>
        )}
        <div className="content">
          <div className="padding">
            <div className="content-top">
              <span className="section-name">{blog?.sectionName?.display}</span>
              <span className="date">
                {" "}
                / {blog && getFormattedDate(blog._created * 1000)}
              </span>
            </div>
            <div className="content-title">
              <h3 className="title">{blog?.title}</h3>
            </div>
            <div className="content-description">
              <p className="description">
                {blog &&
                  (blog.descriptionPreview
                    ? blog.descriptionPreview
                    : striptags(blog.content))}
              </p>
            </div>
          </div>
          <div className="content-more">
            <span className="more">więcej</span>
            <div className="line"></div>
          </div>
          {showTags && (
            <div className="content-tags">
              <div className="tag">
                <span>#{blog?.sectionName.display}</span>
              </div>
              {blog?.tags?.slice(0, 15).map((tag: string) => (
                <div className="tag">
                  <span>#{tag}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </Link>
    );
  }
}

export default withRouter(BlogPreview);
