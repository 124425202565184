import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  PartnersStateProps,
  PartnersDispatchProps,
  PartnersProps,
  PartnersState,
} from "./Partners.types";
import "./Partners.style.scss";
import ContentBox from "../../shared/ContentBox/ContentBox";
import { fetchFromApi, getImageUrl } from "../../../core/utils";
import { Link } from "react-router-dom";

const mapStateToProps = (state: AppState): PartnersStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): PartnersDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Partners extends React.Component<PartnersProps, PartnersState> {
  public state: PartnersState = {
    partnersSingleton: undefined,
    shareholders: [],
  };
  public constructor(props: PartnersProps) {
    super(props);
  }

  public componentDidMount() {
    this.fetchPartnersSingleton();
    this.fetchShareholders();
  }

  public componentDidUpdate(oldProps: PartnersProps, oldState: PartnersState) {}

  public fetchPartnersSingleton = async () => {
    const partnersSingleton = await fetchFromApi<
      PartnersSingleton,
      "singletons"
    >("singletons", "partners");
    this.setState({ partnersSingleton });
  };

  public fetchShareholders = async () => {
    const shareholders = await fetchFromApi<Shareholder, "collections">(
      "collections",
      "shareholders"
    );
    this.setState({ shareholders });
  };

  public render(): JSX.Element {
    const {} = this.props;
    const { partnersSingleton, shareholders } = this.state;

    return (
      <div className={"Partners"}>
        <ContentBox
          imagePath={partnersSingleton?.backgroundImage?.path}
          hideBackButton={true}
          sections={[
            {
              header: "Partnerzy",
              wysiwygable: true,
              item: partnersSingleton?.content3,
              bottomItem: (
                <div className="shareholders">
                  {shareholders
                    .filter((shareholder) => shareholder.kind === "organizer")
                    .map((shareholder) => (
                      <a
                        href={shareholder.url}
                        target="_blank"
                        className="shareholder-wrapper"
                      >
                        <div className="shareholder">
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url(${getImageUrl(
                                shareholder.image
                              )})`,
                            }}
                          ></div>
                        </div>
                      </a>
                    ))}
                </div>
              ),
            },
            {
              header: "Partnerzy",
              wysiwygable: true,
              item: partnersSingleton?.content,
              bottomItem: (
                <div className="shareholders">
                  {shareholders
                    .filter((shareholder) => shareholder.kind === "partner")
                    .map((shareholder) => (
                      <a
                        href={shareholder.url}
                        target="_blank"
                        className="shareholder-wrapper"
                      >
                        <div className="shareholder">
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url(${getImageUrl(
                                shareholder.image
                              )})`,
                            }}
                          ></div>
                        </div>
                      </a>
                    ))}
                  <div className="shareholder-wrapper join">
                    <Link
                      to={"/kontakt?zostan=partnerem"}
                      className="shareholder join"
                    >
                      <h3>Zostań naszym partnerem!</h3>
                    </Link>
                  </div>
                </div>
              ),
            },
            {
              header: "Patroni",
              wysiwygable: true,
              item: partnersSingleton?.content2,
              bottomItem: (
                <div className="shareholders">
                  {shareholders
                    .filter((shareholder) => shareholder.kind === "sponsor")
                    .map((shareholder) => (
                      <a
                        href={shareholder.url}
                        target="_blank"
                        className="shareholder-wrapper"
                      >
                        <div className="shareholder">
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url(${getImageUrl(
                                shareholder.image
                              )})`,
                            }}
                          ></div>
                        </div>
                      </a>
                    ))}
                  <div className="shareholder-wrapper join">
                    <Link
                      to={"/kontakt?zostan=patronem"}
                      className="shareholder join"
                    >
                      <h3>Zostań naszym patronem!</h3>
                    </Link>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export default Partners;
