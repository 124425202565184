import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  LastBlogsStateProps,
  LastBlogsDispatchProps,
  LastBlogsProps,
  LastBlogsState,
} from "./LastBlogs.types";
import "./LastBlogs.style.scss";
import BlogPreview from "../BlogPreview/BlogPreview";
import LastBlogsSet from "../LastBlogsSet/LastBlogsSet";
import blogsMock from "./blogs.mock.json";
import Badge from "../../shared/Badge/Badge";
import { fetchFromApi, schuffle } from "../../../core/utils";
import { withRouter } from "react-router";

const mapStateToProps = (state: AppState): LastBlogsStateProps => ({});

const mapDispatchToProps = (
  dispatch: AppDispatch
): LastBlogsDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class LastBlogs extends React.Component<LastBlogsProps, LastBlogsState> {
  public state: LastBlogsState = {
    blogs: [],
    displayedSets: 1,
  };
  public constructor(props: LastBlogsProps) {
    super(props);
  }

  public componentDidMount() {
    this.fetchBlogs();
  }

  public componentDidUpdate(
    oldProps: LastBlogsProps,
    oldState: LastBlogsState
  ) {}

  public fetchBlogs = async () => {
    const normalBlogs = await fetchFromApi<Blog, "collections">(
      "collections",
      "blogs",
      undefined,
      {
        filter: { isRecommended: false },
        sort: { _created: -1 },
        limit: 15,
      }
    );
    const recommendedBlogs = await fetchFromApi<Blog, "collections">(
      "collections",
      "blogs",
      undefined,
      {
        filter: { isRecommended: true },
        sort: { _created: -1 },
        limit: 15,
      }
    );
    const blogs = [
      ...normalBlogs,
      ...recommendedBlogs.slice(4, recommendedBlogs.length),
    ].slice(0, 15);
    this.setState({ blogs: schuffle([...blogs]) });
  };

  public getBlogSets = (blogs: Entry<Blog>[]): Array<Entry<Blog>[]> => {
    const blogSets = [
      blogs.slice(0, 5),
      blogs.slice(5, 10),
      blogs.slice(10, 15),
    ].filter((blogSet) => blogSet.length === 5);

    return blogSets;
  };

  public handleMoreBadgeClick = () => {
    const { history } = this.props;
    const { displayedSets, blogs } = this.state;
    const blogSets = this.getBlogSets(blogs);

    displayedSets >= blogSets.length
      ? history.push("/blog")
      : this.setState({ displayedSets: displayedSets + 1 });
  };

  public render(): JSX.Element {
    const {} = this.props;
    const { blogs, displayedSets } = this.state;

    const blogSets = this.getBlogSets(blogs);

    return (
      <div className={"lastBlogs"}>
        {blogSets.slice(0, displayedSets).map((blogs, idx) => (
          <LastBlogsSet
            blogs={blogs}
            direction={idx % 2 === 0 ? "ltr" : "rtl"}
          />
        ))}
        <div className="bottom">
          {displayedSets <= blogSets.length && (
            <Badge onClick={this.handleMoreBadgeClick}>+ Więcej</Badge>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(LastBlogs);
