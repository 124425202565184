import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  BlogStateProps,
  BlogDispatchProps,
  BlogProps,
  BlogState,
  BlogDecodedParams,
} from "./Blog.types";
import "./Blog.style.scss";
import ContentBox from "../../shared/ContentBox/ContentBox";
import ReactHtmlParser from "react-html-parser";
import Badge from "../../shared/Badge/Badge";
import { withRouter } from "react-router";
import {
  decodeBlogUrl,
  fetchFromApi,
  getFormattedDate,
  sleep,
} from "../../../core/utils";
import { ImSpinner2 } from "react-icons/im";

const mapStateToProps = (state: AppState): BlogStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): BlogDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class BlogComponent extends React.Component<BlogProps, BlogState> {
  public state: BlogState = {
    blog: undefined,
    prevBlogParams: undefined,
    nextBlogParams: undefined,
    isPending: false,
  };
  public constructor(props: BlogProps) {
    super(props);
  }

  public componentDidMount() {
    this.handleMatchParamsUpdate();
  }

  public componentDidUpdate(oldProps: BlogProps, oldState: BlogState) {
    const { props } = this;
    if (props.match.params.blogCreated !== oldProps.match.params.blogCreated) {
      this.handleMatchParamsUpdate();
    }
  }

  public handleMatchParamsUpdate = () => {
    const { params } = this.props.match;
    const _created = Number(params.blogCreated);
    const title = decodeBlogUrl(params.blogEncodedTitle);
    this.fetchBlog({ _created, title });
  };

  public fetchBlog = async (decodedParams: BlogDecodedParams) => {
    const { isPending } = this.state;
    if (isPending) return;
    const { _created, title } = decodedParams;
    this.setState({ isPending: true, blog: undefined });
    const blogs = await fetchFromApi<Blog, "collections">(
      "collections",
      "blogs",
      undefined,
      { filter: { _created }, limit: 1 }
    );
    const prevBlogs = await fetchFromApi<Blog, "collections">(
      "collections",
      "blogs",
      undefined,
      {
        filter: { _created: { $lt: _created } },
        limit: 1,
        fields: { title: true, _created: true },
        sort: { _created: -1 },
      }
    );
    const nextBlogs = await fetchFromApi<Blog, "collections">(
      "collections",
      "blogs",
      undefined,
      {
        filter: { _created: { $gt: _created } },
        limit: 1,
        fields: { title: true, _created: true },
      }
    );
    await sleep(1000);
    this.setState({
      blog: blogs.length ? blogs[0] : undefined,
      prevBlogParams: prevBlogs.length ? prevBlogs[0] : undefined,
      nextBlogParams: nextBlogs.length ? nextBlogs[0] : undefined,
      isPending: false,
    });
  };

  public handlePrevBlogBadgeClick = () => {
    const { history } = this.props;
    const { prevBlogParams } = this.state;
    if (!prevBlogParams) return;
    const { _created, title } = prevBlogParams;
    history.push(`/blog/${_created}/${decodeBlogUrl(title)}`);
  };

  public handleNextBlogBadgeClick = () => {
    const { history } = this.props;
    const { nextBlogParams } = this.state;
    if (!nextBlogParams) return;
    const { _created, title } = nextBlogParams;
    history.push(`/blog/${_created}/${decodeBlogUrl(title)}`);
  };

  public render(): JSX.Element {
    const {} = this.props;
    const { blog, isPending, prevBlogParams, nextBlogParams } = this.state;

    return (
      <div className={"blog"}>
        <ContentBox
          imagePath={
            blog?.image.path ||
            "https://scontent.fpoz4-1.fna.fbcdn.net/v/t1.15752-9/245448366_1242296639622045_3851520295798303655_n.png?_nc_cat=104&ccb=1-5&_nc_sid=ae9488&_nc_ohc=mxQ7jI4r6y0AX9Re-Oj&_nc_ht=scontent.fpoz4-1.fna&oh=a9ebcd65066a377eb2bd368e9d77aa00&oe=618C2CCF"
          }
          hideBackButton={true}
          sections={[
            {
              header: "Blog",
              item: (
                <div className="blog-item">
                  {blog !== undefined && (
                    <div className="blog-box">
                      <div className="box-top blog-padding">
                        <span>{blog.sectionName?.display}</span>
                        <span> / {getFormattedDate(blog._created * 1000)}</span>
                        <div className="line"></div>
                      </div>
                      <h2 className="box-header blog-padding">{blog.title}</h2>
                      <div className="box-author-and-sponsor">
                        <div className="box-author blog-padding">
                          <span>{blog.author}</span>
                        </div>
                        {blog.articleSponsorImage && (
                          <a
                            target="_blank"
                            href={blog.articleSponsorUrl}
                            className="box-sponsor blog-padding"
                            style={{
                              backgroundImage: `url(${blog.articleSponsorImage.path})`,
                            }}
                          ></a>
                        )}
                      </div>
                      <div className="box-content blog-padding">
                        {ReactHtmlParser(blog.content)}
                      </div>
                    </div>
                  )}
                  {blog === undefined && isPending && (
                    <div className="loading">
                      <ImSpinner2
                        className="spinner"
                        color={"#999"}
                        size={35}
                      />
                    </div>
                  )}
                </div>
              ),
            },
          ]}
          bottom={
            <>
              {
                <Badge
                  disabled={Boolean(!prevBlogParams) || isPending}
                  onClick={this.handlePrevBlogBadgeClick}
                >
                  Poprzedni
                </Badge>
              }
              {
                <Badge
                  disabled={Boolean(!nextBlogParams) || isPending}
                  onClick={this.handleNextBlogBadgeClick}
                >
                  Następny
                </Badge>
              }
            </>
          }
        />
      </div>
    );
  }
}

export default withRouter(BlogComponent);
