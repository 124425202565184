import React from "react";
import { connect } from "react-redux";
import { AppState, AppDispatch } from "../../../core/store";
import {
  BlogsStateProps,
  BlogsDispatchProps,
  BlogsProps,
  BlogsState,
} from "./Blogs.types";
import "./Blogs.style.scss";
import ContentBox from "../../shared/ContentBox/ContentBox";
import blogsMock from "../../entities/LastBlogs/blogs.mock.json";
import BlogPreview from "../../entities/BlogPreview/BlogPreview";
import { withRouter } from "react-router";
import { fetchFromApi, sleep } from "../../../core/utils";
import qs from "qs";
import { ImSpinner2 } from "react-icons/im";
import Badge from "../../shared/Badge/Badge";

const mapStateToProps = (state: AppState): BlogsStateProps => ({});

const mapDispatchToProps = (dispatch: AppDispatch): BlogsDispatchProps => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Blogs extends React.Component<BlogsProps, BlogsState> {
  public textBoxRef: React.RefObject<HTMLDivElement> = React.createRef();
  public state: BlogsState = {
    sections: [],
    isBlogsPending: false,
    isRecommendedBlogsPending: false,
    blogs: [],
    recommendedBlogs: [],
  };
  public constructor(props: BlogsProps) {
    super(props);
  }

  public componentDidMount() {
    this.fetchSections();
    this.handleLocationSearchUpdate();
  }

  public componentDidUpdate(oldProps: BlogsProps, oldState: BlogsState) {
    const { props } = this;
    if (props.location.search !== oldProps.location.search)
      this.handleLocationSearchUpdate();
  }

  public getSelectedSectionName = () => {
    const { location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const sectionName =
      query["sekcja"] === undefined ? undefined : String(query["sekcja"]);
    return sectionName;
  };

  public handleLocationSearchUpdate = () => {
    this.fetchNormalBlogs();
    this.fetchRecommendedBlogs();
  };

  public scrollToTopBox = async () => {
    const topBoxElement = this.textBoxRef.current;
    if (!topBoxElement) return;
    await sleep(500);
    topBoxElement.scrollIntoView({ behavior: "smooth" });
  };

  public fetchSections = async () => {
    const sections = await fetchFromApi<Section, "collections">(
      "collections",
      "sections"
    );
    this.setState({ sections });
  };

  public fetchBlogs = async (
    filter: {
      sectionName?: SectionName | undefined;
      isRecommended?: boolean | undefined;
    },
    limit: number | undefined,
    skip: number | undefined
  ) => {
    const blogs = await fetchFromApi<Blog, "collections">(
      "collections",
      "blogs",
      undefined,
      { filter, limit, skip, sort: { _created: -1 } }
    );

    return blogs;
  };

  public fetchNormalBlogs = async () => {
    const { isBlogsPending } = this.state;
    if (isBlogsPending) return;
    const selectedSectionName = this.getSelectedSectionName();

    this.setState({ isBlogsPending: true, blogs: [] });
    const blogs = await this.fetchBlogs(
      { sectionName: selectedSectionName },
      10,
      0
    );
    await this.scrollToTopBox();
    this.setState({ blogs, isBlogsPending: false });
  };

  public appendNormalBlogs = async () => {
    const { isBlogsPending, blogs: oldBlogs } = this.state;
    if (isBlogsPending) return;
    const selectedSectionName = this.getSelectedSectionName();

    this.setState({ isBlogsPending: true });
    const blogs = await this.fetchBlogs(
      { sectionName: selectedSectionName },
      10,
      oldBlogs.length
    );
    await sleep(3000);
    this.setState({ blogs: [...oldBlogs, ...blogs], isBlogsPending: false });
  };

  public fetchRecommendedBlogs = async () => {
    const { isRecommendedBlogsPending } = this.state;
    if (isRecommendedBlogsPending) return;
    const selectedSectionName = this.getSelectedSectionName();

    this.setState({ isRecommendedBlogsPending: true });
    const recommendedBlogs = await this.fetchBlogs(
      {
        isRecommended: true,
        sectionName: selectedSectionName,
      },
      5,
      0
    );
    this.setState({ recommendedBlogs, isRecommendedBlogsPending: false });
  };

  public handleSectionClick = (section: Section | undefined) => {
    const { location, history } = this.props;
    section ? history.push(`?sekcja=${section.name}`) : history.push("?");
  };

  public handleMoreBadgeClick = () => {
    this.appendNormalBlogs();
  };

  public render(): JSX.Element {
    const {} = this.props;
    const {
      sections,
      blogs,
      recommendedBlogs,
      isRecommendedBlogsPending,
      isBlogsPending,
    } = this.state;

    const selectedSectionName = this.getSelectedSectionName();

    return (
      <div className={"blogs"}>
        <ContentBox
          imagePath={
            "https://scontent.fpoz4-1.fna.fbcdn.net/v/t1.15752-9/245448366_1242296639622045_3851520295798303655_n.png?_nc_cat=104&ccb=1-5&_nc_sid=ae9488&_nc_ohc=mxQ7jI4r6y0AX9Re-Oj&_nc_ht=scontent.fpoz4-1.fna&oh=a9ebcd65066a377eb2bd368e9d77aa00&oe=618C2CCF"
          }
          slides={recommendedBlogs.slice(0, 5).map((blog) => ({
            image: blog.image,
            content: (
              <BlogPreview
                className="blogs-slide"
                blog={blog}
                hideImage={true}
              />
            ),
          }))}
          hideBackButton={true}
          sections={[
            {
              header: "Blog",
              textBoxRef: this.textBoxRef,
              item: (
                <div className="blog-item">
                  <div className="sections">
                    {[undefined, ...sections].map((section) => (
                      <button
                        className={`section ${
                          section?.name === selectedSectionName && "selected"
                        } ${
                          section && section?.name.length > 12 ? "lvl2" : ""
                        }`}
                        disabled={isBlogsPending || isRecommendedBlogsPending}
                        onClick={() => this.handleSectionClick(section)}
                      >
                        <span>{section?.name || "Wszystkie"}</span>
                      </button>
                    ))}
                  </div>

                  <div className="blogs-previews">
                    {blogs.map((blog) => (
                      <BlogPreview
                        className="blogs-blog"
                        blog={blog}
                        showTags={true}
                      />
                    ))}
                  </div>
                  {(isBlogsPending || isRecommendedBlogsPending) && (
                    <div className="loading-box">
                      <ImSpinner2
                        className="spinner"
                        color={"#999"}
                        size={35}
                      />
                    </div>
                  )}
                </div>
              ),
            },
          ]}
          bottomStyle={{ justifyContent: "flex-end" }}
          bottom={
            <div className="blog-bottom">
              <Badge
                onClick={this.handleMoreBadgeClick}
                disabled={isBlogsPending || isRecommendedBlogsPending}
              >
                Więcej
              </Badge>
            </div>
          }
        />
      </div>
    );
  }
}

export default withRouter(Blogs);
